export const PROPERTY_TYPES = [
  {
    label: 'Woonvastgoed',
    children: [
      {
        label: 'Woning',
        value: 3,
        children: [
          {
            label: 'Villa',
            value: 8,
            children: [
              { label: 'Bungalow', value: 87 },
              { label: 'Hoeve', value: 35 },
              { label: 'Landhuis', value: 75 },
              { label: 'Herenhuis', value: 41 },
              { label: 'Kasteel', value: 73 }
            ]
          },
          { label: 'Huis met magazijn', value: 88 },
          { label: 'Woonboot', value: 89 },
          { label: 'Chalet', value: 90 },
          { label: 'Kangoeroewoning', value: 97 }
        ]
      },
      {
        label: 'Appartement',
        value: 5,
        children: [
          {
            label: 'Dakappartement',
            value: 76,
            children: [
              { label: 'Loft', value: 51 },
              { label: 'Penthouse', value: 47 }
            ]
          },
          { label: 'Gelijkvloers appartement', value: 53 },
          { label: 'Studio', value: 6 },
          { label: 'Studentenkamer', value: 59 },
          { label: 'Duplex', value: 91 },
          { label: 'Triplex', value: 92 },
          { label: 'Assistentiewoning', value: 93 }
        ]
      },
      {
        label: 'Grond',
        value: 11,
        children: [
          { label: 'Bouwgrond', value: 94 },
          { label: 'Landbouwgrond', value: 95 }
        ]
      },
      {
        label: 'Garage',
        value: 12,
        children: [
          { label: 'Staanplaats', value: 22 },
          { label: 'Garagebox', value: 23 }
        ]
      }
    ]
  },
  {
    label: 'Bedrijfsvastgoed',
    children: [
      {
        label: 'Handel',
        value: 71,
        children: [
          { label: 'Handelspand', value: 31 },
          { label: 'Handelspand met woonst', value: 25 },
          { label: 'Handelspand met magazijn', value: 86 }
        ]
      },
      {
        label: 'Horeca',
        value: 2,
        children: [
          { label: 'Eetgelegenheid', value: 77 },
          { label: 'Café', value: 79 },
          { label: 'Hotel / B&B', value: 64 },
          { label: 'Feestzaal', value: 83 }
        ]
      },
      {
        label: 'Industrie',
        value: 74,
        children: [
          {
            label: 'Magazijn',
            value: 80,
            children: [
              { label: 'Magazijn met woonst', value: 34 },
              { label: 'Magazijn met kantoor', value: 26 }
            ]
          },
          { label: 'Werkplaats', value: 82 },
          { label: 'Bedrijfsgebouw', value: 28 }
        ]
      },
      {
        label: 'Kantoor',
        value: 14,
        children: [
          { label: 'Kantoor met woonst', value: 49 },
          { label: 'Kantoorgebouw', value: 36 }
        ]
      },
      {
        label: 'Bedrijfsgrond',
        value: 81,
        children: [
          { label: 'Projectgrond', value: 63 },
          { label: 'Industriegrond', value: 33 }
        ]
      }
    ]
  },
  {
    label: 'Overige',
    children: [
      { label: 'Opbrengsteigendom', value: 85 },
      { label: 'Patrimoniumvennootschap', value: 96 }
    ]
  }
]

export const RESIDENTIAL_PROPERTY_TYPES = [3, 8, 87, 35, 75, 41, 73, 88, 89, 90, 5, 76, 51, 47, 53, 6, 59, 91, 92, 93, 11, 94, 95, 12, 22, 23]
export const COMMERCIAL_PROPERTY_TYPES = [71, 31, 25, 86, 2, 77, 79, 64, 83, 74, 80, 34, 26, 82, 28, 14, 49, 36, 81, 63, 33]
export const OTHER_PROPERTY_TYPES = [85, 96]

export const REGIONS = {
  0: 'Vlaanderen',
  1: 'Brussel',
  2: 'Wallonië'
}

export const NICHES = {
  1: 'Woonvastgoed',
  2: 'Bedrijfsvastgoed'
}

export const EPC_LABELS = [
  { label: 'A+', value: 1 },
  { label: 'A', value: 2 },
  { label: 'B', value: 3 },
  { label: 'C', value: 4 },
  { label: 'D', value: 5 },
  { label: 'E', value: 6 },
  { label: 'F', value: 7 },
  { label: 'G', value: 8 },
  { label: 'X', value: 9 }
]

export const CONCEPT_OPTIONS = {
  0: 'Classic',
  1: 'Pre-sale',
  3: 'Rent to Buy',
  4: 'Publieke veiling',
  5: 'Power-sale',
  6: 'Do it yourself'
}

export const PROPERTY_STATUSES = [
  { value: 1, label: 'Prospect' },
  { value: 16, label: 'Dossieropbouw' },
  { id: 'disabled_1', label: '----------', disabled: true },
  { value: 2, label: 'Te koop' },
  { value: 3, label: 'Te huur' },
  { value: 4, label: 'Over te nemen' },
  { id: 'disabled_2', label: '----------', disabled: true },
  { value: 10, label: 'Verkocht' },
  { value: 11, label: 'Verhuurd' },
  { value: 12, label: 'Overgenomen' },
  { id: 'disabled_3', label: '----------', disabled: true },
  { value: 5, label: 'Beheer' },
  { value: 6, label: 'Syndic' },
  { id: 'disabled_4', label: '----------', disabled: true },
  { value: 15, label: 'Uit portefeuille' }
]

export const ADDRESS_PUBLISH_OPTIONS = {
  0: 'Publiceer adres niet',
  1: 'Publiceer adres zonder huisnummer',
  2: 'Publiceer adres'
}

export const BID_TYPE_OPTIONS = {
  0: 'Transparante bieding',
  1: 'Gesloten bieding'
}

export const PROPERTY_TRANSACTION_TYPES = [
  { value: 2, label: 'Verkoopdossier' },
  { value: 3, label: 'Verhuurdossier' },
  { value: 4, label: 'Overnamedossier' },
  { value: 5, label: 'Beheerdossier' },
  { value: 6, label: 'Syndicdossier' }
]

export const USAGE_TYPES = [
  { value: 'B', label: 'Belegging' },
  { value: 'E', label: 'Eigen gebruik' },
  { value: 'O', label: 'Onbekend' }
]

export const PROPERTY_ROOM_TYPES = [
  { value: 0, label: 'Andere' },
  { value: 2, label: 'Badkamer' },
  { value: 14, label: 'Balkon' },
  { value: 9, label: 'Berging' },
  { value: 10, label: 'Bureau' },
  { value: 18, label: 'Carport' },
  { value: 11, label: 'Dressing' },
  { value: 12, label: 'Eetkamer' },
  { value: 6, label: 'Garage' },
  { value: 19, label: 'Inkomhal' },
  { value: 16, label: 'Kelder' },
  { value: 3, label: 'Keuken' },
  { value: 1, label: 'Slaapkamer' },
  { value: 15, label: 'Terras' },
  { value: 7, label: 'Toilet' },
  { value: 4, label: 'Tuin' },
  { value: 17, label: 'Veranda' },
  { value: 5, label: 'Woonkamer' },
  { value: 13, label: 'Zwembad' },
  { value: 8, label: 'Zolder' }
]

export const RENTAL_MANAGEMENT_TYPES = {
  0: 'Klassieke verhuur',
  1: 'Klassiek beheer',
  2: 'Financieel beheer',
  3: 'Beheer met betaalzekerheid',
  4: 'Beheer RZK',
  5: 'Technisch beheer (enkel Brussel)'
}
